import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Nav from "../Components/Nav";
import { motion } from "framer-motion";
import { AiFillPrinter } from "react-icons/ai";

const Bestilling = () => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  return (
    <div>
      <Nav />

      <div className=" h-[60vh] w-[100vw] flex flex-wrap justify-center gap-5 mt-[10vh]">
        <div className="relative h-[50vh] w-[80vw] object-cover flex flex-wrap justify-center mt-[2vh]">
          <button onClick={handlePrint} className="w-full h-[5vh] text-white text-2xl flex justify-center items-center">
            <AiFillPrinter />
          </button>
          <img ref={printRef} className="" src="/Assets/Bestilling1.jpg" />
        </div>

      {/*   <div className=" h-[100%] w-[100vw] grid gap-5 mt-[0vh]">
        <div className="relative border-b w-[100vw] object-cover flex flex-wrap justify-center mt-[2vh]">
          <img ref={printRef2} className="w-[80%] object-cover" src="/Assets/Bestilling1.jpg" />
          <button onClick={handlePrint2} className="w-full h-[5vh] text-white text-2xl flex justify-center items-center">
            <AiFillPrinter />
          </button>
        </div>
        </div>

        <div className=" h-[100%] w-[100vw] grid gap-5 mt-[0vh]">
        <div className="relative border-b w-[100vw] object-cover flex flex-wrap justify-center mt-[2vh]">
          <img ref={printRef} className="w-[80%] object-cover" src="/Assets/Bestilling2.jpg" />
          <button onClick={handlePrint} className="w-full h-[5vh] text-white text-2xl flex justify-center items-center">
            <AiFillPrinter />
          </button>
        </div>
        </div>
        
        <div className=" h-[100%] w-[100vw] grid gap-5 mt-[0vh]">
        <div className="relative border-b w-[100vw] object-cover flex flex-wrap justify-center mt-[2vh]">
          <img ref={printRef} className="w-[80%] object-cover" src="/Assets/Bestilling3.jpg" />
          <button onClick={handlePrint} className="w-full h-[5vh] text-white text-2xl flex justify-center items-center">
            <AiFillPrinter />
          </button>
        </div>
        </div> */}
      </div>
    </div>
  );
};

export default Bestilling;
