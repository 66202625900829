import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";



const standardpris = "50,00 DKK";
const højpris = "55,00 DKK";
const Trekant = "16,00 DKK";

const Sandwich = () => {
  return (
    <div>
    
      <Nav />
      <div className=" relative h-[100vh] gap-5 max-h-screen">
        <p className="text-white text-center text-3xl Bebas mt-[13vh] border-b">
          Sandwich
        </p>
        <p className="text-white text-center text-md Bebas mt-[5vh]">
          Alle sandwich laves med <br /> salatmayonnaise, salat, tomat & agurk
        </p>
        <p className="text-white text-center text-md Bebas mt-[1vh]">
          Ekstra fyld pr. styk: <span className="text-green-400">7,00 DKK</span>
        </p>
        <p className="text-white text-center text-md Bebas mt-[2vh]">
          Vælg mellem lys/mørk Baguette & Chilibrød, <br />
          samt følgende fyld:
        </p>
        <div className="mt-[2vh] mb-5 overflow-y-auto w-full small:flex flex-wrap justify-center items-center">
          <Item
            img="/Assets/kyllingsand.jpg"
            name="Kylling/Bacon"
            desc="m. Hjemmelavet karrydressing"
            price="50 DKK"
          />
          <Item
            img="/Assets/2-2.jpg"
            name="Flæskesteg m. Rødkål & agurkesalat"
            desc="+ Sennep hvis det ønskes"
            price="50 DKK"
          />
          <Item
            img="/Assets/frikasand.jpg"
            name="Frikadelle m. Rødkål & agurkesalat"
            price="50 DKK"
          />
          <Item
            img="/Assets/2-3.jpg"
            name="Skinke/ost"
            desc="Med eller uden Dijonsennep"
            price={standardpris}
          />
          <Item
            img="/Assets/template.png"
            name="Skinke m. Hjemmelavet røræg"
            price={standardpris}
          />
          <Item
            img="/Assets/sandæg.jpg"
            name="Æg & Rejer"
            price={standardpris}
          />
          <Item
            img="/Assets/1-7.jpg"
            name="Æg & Tomat"
            price={standardpris}
          />
          <Item
            img="/Assets/template.png"
            name="Æg & Bacon"
            price={standardpris}
          />
          <Item
            img="/Assets/kebab.jpg"
            name="Kebab m. Chilimayonnaise"
            desc="& syltede løg"
            price={standardpris}
          />
          <Item
            img="/Assets/1-10.jpg"
            name="Rullepølse m. Rå løg"
            price={standardpris}
          />
          <Item
            img="/Assets/template.png"
            name="Spegepølse m. Rå løg"
            desc="Eller remoulade & ristet løg"
            price={standardpris}
          />
          <Item
            img="/Assets/tunsalat.jpg"
            name="Tunsalat m. Rå løg"
            price={standardpris}
          />
          <Item
            img="/Assets/sandkart.jpg"
            name="Kartoffel m. Tomat, mayonnaise,"
            desc="ristet & rå løg"
            price={standardpris}
          />
          <Item
            img="/Assets/2-5.jpg"
            name="Roastbeef m. Remoulade, ristet løg,"
            desc="peberrod & drueagurk"
            price={højpris}
          />
          <Item
            img="/Assets/2-4.jpg"
            name="Laks m. Hjemmelavet røræg"
            price={højpris}
          />
          <Item
            img="/Assets/template.png"
            name="Pulled Pork m."
            desc="hjemmelavet coleslaw"
            price={højpris}
          />
          <Item
            img="/Assets/template.png"
            name="Serrano-/Parmaskinke"
            desc="m. Pesto & brieost"
            price={højpris}
          />
          <Item
            img="/Assets/2-18.jpg"
            name="Trekant sandwich"
            price={Trekant}
          />
        </div>
      </div>
    </div>
  );
};

export default Sandwich;
