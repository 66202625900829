import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";
import { motion } from "framer-motion";

const håndmad = "16,00 DKK";
const smørrebrød = "27,00 DKK";
const luksus = "45,00 DKK";

const Smørrebrød = () => {
  return (
    <div>
      <Nav />
      <div className=" relative h-[100vh] med:w-[100vw] gap-5 max-h-screen">
        <p className="text-white text-center text-3xl Bebas mt-[13vh] border-b">
          Smørrebrød
        </p>
        <p className="text-white text-center text-2xl Bebas mt-[5vh]">
          Vælg mellem:
        </p>
        <div className="flex justify-end w-[100vw] ">
          <div className=" w-[50%] mr-[52px] text-left ">
            <p className="text-white text-md Bebas mt-[1vh]">
              Håndmad: <span className="text-green-400">16,00 DKK</span>
              <br />
              Smørrebrød: <span className="text-green-400">27,00 DKK</span>
              <br />
              Luksus Smørrebrød:{" "}
              <span className="text-green-400">45,00 DKK</span>
              <br />
              Ekstra Tilbehør pr. Styk:{" "}
              <span className="text-green-400">7,00 DKK</span>
            </p>
          </div>
        </div>
        <div className="mt-[5vh] mb-5 overflow-y-auto w-full med: small:flex small:flex-wrap justify-center items-center">
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Item
              img="/Assets/1-1.jpg"
              name="Æg & rejer"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Item
              img="/Assets/1-2.jpg"
              name="Æg & tomat"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <Item
              img="/Assets/template.png"
              name="Æg & sild"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <Item
              img="/Assets/1-19.jpg"
              name="Kartoffel m. ristet & rå løg"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.5 }}
          >
            <Item
              img="/Assets/1-8.jpg"
              name="Dyrlægens natmad"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.7 }}
          >
            <Item
              img="/Assets/1-3.jpg"
              name="Leverpostej m. bacon"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 3 }}
          >
            <Item
              img="/Assets/1-6.jpg"
              name="Flæskesteg m. surt"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 3.2 }}
          >
            <Item
              img="/Assets/frikamad.jpg"
              name="Frikadelle m. surt"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 3.4 }}
          >
            <Item img="/Assets/1-9.jpg" name="Roastbeef" price={håndmad} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 3.6 }}
          >
            <Item
              img="/Assets/template.png"
              name="Skinke m. røræg"
              price={håndmad + " | " + smørrebrød + " | " + luksus}
            />
          </motion.div>
          <Item
            img="/Assets/1-11.jpg"
            name="Skinke m. italiensk salat"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-20.jpg"
            name="Hjemmelavet wienersalat"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/template.png"
            name="Rullepølse m. italiensk salat"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-22.jpg"
            name="Rullepølse m. sky & rå løg"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-15.jpg"
            name="Spegepølse m. remoulade & ristet løg"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-23.jpg"
            name="Spegepølse m. sky & rå løg"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-17.jpg"
            name="Fiskefrikadelle m. remoulade & citron"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/1-18.jpg"
            name="Oksebryst m. Pickles & Peberrod"
            price={håndmad + " | " + smørrebrød + " | " + luksus}
          />
          <Item
            img="/Assets/fiskefiletrejer.jpg"
            name="Fiskefilet m. Remoulade,"
            desc="mayonnaise & rejer"
            price="35,00 DKK | 55,00 DKK"
          />
          <Item
            img="/Assets/1-14.jpg"
            name="Fiskefilet m. Remoulade,"
            desc="citron & agurk"
            price="30,00 DKK | 50,00 DKK"
          />
          <Item
            img="/Assets/1-21.jpg"
            name="Ostemad"
            desc="Stærk eller mellemlagret"
            price="30,00 DKK"
          />
          <Item
            img="/Assets/template.png"
            name="Franskbrød m. ost"
            desc=""
            price="30,00 DKK"
          />
          <Item
            img="/Assets/template.png"
            name="Franskbrød m. rullepølse eller"
            desc="spegepølse"
            price="30,00 DKK"
          />
          <Item
            img="/Assets/laksfranskbrød.jpg"
            name="Franskbrød m. Rejer eller Laks"
            desc=""
            price="60,00 DKK"
          />
        </div>
      </div>
    </div>
  );
};

export default Smørrebrød;
