import Preloader from "./Preloader";
import Nav from "../Components/Nav";
import { Link } from "react-router-dom";

const Load = () => {
  return (
    <div className="">
      <div className="mask-container bg-black">
        <Preloader />
      </div>
      <div className=" h-[100vh] w-[100vw]">
        <div className="relative h-[30vh] large:h-[45vh] w-full mt-[9.5vh] large:mt-[8vh] object-cover">
          <img
            src="/Assets/front2.jpg"
            className="w-full h-full object-cover z-20 border-b-2"
          ></img>
          <h1 className="absolute z-20 text-black text-[30px] large:text-[50px] mt-[-28vh] large:mt-[-40vh] ml-[5vw] Vibes leading-[2rem]">
            Velkommen til <br />
            {/* <span className="Bebas">Præstø Smørrebrød</span> */}
          </h1>
          {/* <p className="absolute z-20 text-white text-sm mt-[-17vh] ml-[5vw]">
            Lorem Ipsum is simply dummy text <br /> of the printing and
            typesetting industry.
          </p> */}
        </div>

        <div className="relative h-[37vh] w-full list-none text-center border-b border-gray-700">
          <h2 className="text-green-400 Bebas z-20 text-3xl text-center mt-[3vh] animate-bounce">
            Åbningstider
          </h2>
          <div className="flex justify-end w-[100vw]">
            <div className="w-[50%] mr-[62px] text-left">
            
              <li className="text-white mt-[3vh] z-20">
                Mandag:{" "}
                <span className="text-green-400 Bebas">09.00-14.30</span>
              </li>             
              <li className="text-white z-20">
                Tirsdag:{" "}
                <span className="text-green-400 Bebas z-20">09.00-14.30</span>
              </li>
              <li className="text-white">
                Onsdag:{" "}
                <span className="text-green-400 Bebas">09.00-14.30</span>
              </li>
              <li className="text-white">
                Torsdag:{" "}
                <span className="text-green-400 Bebas">09.00-14.30</span>
              </li>
              <li className="text-white">
                Fredag:{" "}
                <span className="text-green-400 Bebas">09.00-14.30</span>
              </li>
              <li className="text-white">
                Lørdag:{" "}
                <span className="text-yellow-500 Bebas">Forudbestilling</span>
              </li>
              
              <li className="text-white">
                Søndag: <span className="text-rose-600 Bebas">Lukket</span>
              </li>
              
            </div>
            
          </div>
        </div>
        <div className="relative h-[30vh] w-full list-none text-center">
          <h3 className="text-green-400 Bebas text-3xl text-center mt-[3vh] animate-bounce">
            Bestilling
          </h3>
          <li className="text-white mt-[3vh] relative">
            <span className="text-white absolute small:ml-[-6vw] med:ml-[-4vw] large:ml-[-2vw] mt-[0.8vh]">
              <svg
                width="15"
                height="15"
                viewBox="0 0 59 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.2656 0.00259828C47.0271 0.00259828 59 11.9754 59 26.737C59 28.2696 57.767 29.5026 56.2344 29.5026C54.7018 29.5026 53.4688 28.2696 53.4688 26.737C53.4688 15.0292 43.9734 5.53385 32.2656 5.53385C30.733 5.53385 29.5 4.30084 29.5 2.76822C29.5 1.23561 30.733 0.00259828 32.2656 0.00259828ZM33.1875 22.1276C34.1655 22.1276 35.1034 22.5161 35.795 23.2076C36.4865 23.8992 36.875 24.8371 36.875 25.8151C36.875 26.7931 36.4865 27.731 35.795 28.4226C35.1034 29.1141 34.1655 29.5026 33.1875 29.5026C32.2095 29.5026 31.2716 29.1141 30.58 28.4226C29.8885 27.731 29.5 26.7931 29.5 25.8151C29.5 24.8371 29.8885 23.8992 30.58 23.2076C31.2716 22.5161 32.2095 22.1276 33.1875 22.1276ZM29.5 13.8307C29.5 12.2981 30.733 11.0651 32.2656 11.0651C40.9197 11.0651 47.9375 18.0829 47.9375 26.737C47.9375 28.2696 46.7045 29.5026 45.1719 29.5026C43.6393 29.5026 42.4062 28.2696 42.4062 26.737C42.4062 21.1366 37.866 16.5963 32.2656 16.5963C30.733 16.5963 29.5 15.3633 29.5 13.8307ZM13.54 0.163926C15.7756 -0.446816 18.1148 0.694005 19.0021 2.83736L23.6115 13.8999C24.3951 15.7782 23.8535 17.9561 22.2748 19.2352L16.5938 23.8907C20.4311 32.0032 26.9994 38.5715 35.1119 42.4088L39.7559 36.7278C41.0465 35.1491 43.2129 34.6075 45.0912 35.3911L56.1537 40.0005C58.2971 40.8878 59.4379 43.227 58.8271 45.4626L56.0615 55.6032C55.5199 57.6083 53.6992 59.0026 51.625 59.0026C23.116 59.0026 0 35.8866 0 7.3776C0 5.30338 1.39434 3.48268 3.39941 2.92955L13.54 0.163926Z"
                  fill="url(#paint0_linear_827_6)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_827_6"
                    x1="29.5"
                    y1="0"
                    x2="29.5"
                    y2="59.0026"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            +45 42 60 47 20
          </li>
          <p className="text-white text-xl mt-[3vh] ml-[0vw] uppercase Bebas">
            Jernbanevej 4 4720 Præstø
          </p>
          
        </div>

        <div className="relative h-[30vh] w-full mt-[0vh] object-cover">
          <img
            src="/Assets/5004.png"
            className="w-full h-full object-cover border-t-2"
          ></img>
          <h1 className="absolute z-20 text-white text-[30px] mt-[-28vh] ml-[5vw] Vibes leading-[2rem]">
            Følg os på:
          </h1>
          <div className="w-full h-[10vh] absolute flex justify-evenly mt-[-19vh] items-center">
            <a href="https://www.facebook.com/Smorrbroed">
              <svg
                width="35"
                height="35"
                viewBox="0 0 212 211"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M212 105.893C212 47.4036 164.548 0 106 0C47.4516 0 0 47.4036 0 105.893C0 155.53 34.243 197.226 80.4109 208.684V138.24H58.5484V105.893H80.4109V91.9531C80.4109 55.9247 96.725 39.2135 132.169 39.2135C138.877 39.2135 150.47 40.5371 155.232 41.8608V71.1468C152.748 70.8986 148.4 70.7332 142.976 70.7332C125.585 70.7332 118.877 77.3101 118.877 94.3936V105.893H153.493L147.53 138.24H118.836V211C171.339 204.671 212 160.039 212 105.893Z"
                  fill="white"
                />
              </svg>
            </a>
            {/* <svg
              width="35"
              height="35"
              viewBox="0 0 212 212"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M106.024 51.6459C75.944 51.6459 51.6816 75.9136 51.6816 106C51.6816 136.086 75.944 160.354 106.024 160.354C136.103 160.354 160.366 136.086 160.366 106C160.366 75.9136 136.103 51.6459 106.024 51.6459ZM106.024 141.337C86.5854 141.337 70.6943 125.49 70.6943 106C70.6943 86.5101 86.5381 70.6627 106.024 70.6627C125.509 70.6627 141.353 86.5101 141.353 106C141.353 125.49 125.462 141.337 106.024 141.337ZM175.264 49.4225C175.264 56.471 169.588 62.1004 162.589 62.1004C155.542 62.1004 149.913 56.4237 149.913 49.4225C149.913 42.4213 155.589 36.7446 162.589 36.7446C169.588 36.7446 175.264 42.4213 175.264 49.4225ZM211.255 62.2896C210.451 45.3069 206.573 30.2638 194.134 17.8697C181.743 5.47562 166.703 1.59656 149.724 0.745063C132.225 -0.248354 79.7749 -0.248354 62.2757 0.745063C45.3441 1.54926 30.3043 5.42832 17.8657 17.8224C5.42711 30.2164 1.59621 45.2596 0.744897 62.2423C-0.248299 79.7454 -0.248299 132.207 0.744897 149.71C1.54891 166.693 5.42711 181.736 17.8657 194.13C30.3043 206.524 45.2968 210.403 62.2757 211.255C79.7749 212.248 132.225 212.248 149.724 211.255C166.703 210.451 181.743 206.572 194.134 194.13C206.526 181.736 210.404 166.693 211.255 149.71C212.248 132.207 212.248 79.7927 211.255 62.2896ZM188.648 168.491C184.959 177.763 177.818 184.906 168.5 188.643C154.548 194.178 121.442 192.9 106.024 192.9C90.6055 192.9 57.4516 194.13 43.5469 188.643C34.2771 184.953 27.1355 177.81 23.3992 168.491C17.8657 154.536 19.1427 121.422 19.1427 106C19.1427 90.5784 17.913 57.4172 23.3992 43.5093C27.0882 34.2374 34.2298 27.0943 43.5469 23.3571C57.4989 17.8224 90.6055 19.0996 106.024 19.0996C121.442 19.0996 154.596 17.8697 168.5 23.3571C177.77 27.047 184.912 34.1901 188.648 43.5093C194.182 57.4645 192.905 90.5784 192.905 106C192.905 121.422 194.182 154.583 188.648 168.491Z"
                fill="white"
              />
            </svg> */}
          </div>
        </div>

        <div className="relative h-[13vh] w-full list-none text-center ">
          <div className="text-white text-sm mt-[3vh] ml-[-0vw] relative">
            Alle rettigheder forbeholdes <br />
            <div className="small:ml-[-15vw] med:ml-[-5vw] xtreme:ml-[-3vw]">
              Præstø Smørrebrød{" "}
              <span className="absolute small:ml-[6vw] med:ml-[3vw] large:ml-[30px] xtreme:ml-[40px]">2023</span>
              <span className="absolute mt-[0.6vh] small:ml-[2vw] med:ml-[1vw] xtreme:ml-[0.7vw]">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 5.0625C32.8182 5.0625 38.3981 7.37377 42.5122 11.4878C46.6262 15.6019 48.9375 21.1818 48.9375 27C48.9375 32.8182 46.6262 38.3981 42.5122 42.5122C38.3981 46.6262 32.8182 48.9375 27 48.9375C21.1818 48.9375 15.6019 46.6262 11.4878 42.5122C7.37377 38.3981 5.0625 32.8182 5.0625 27C5.0625 21.1818 7.37377 15.6019 11.4878 11.4878C15.6019 7.37377 21.1818 5.0625 27 5.0625ZM27 54C34.1608 54 41.0284 51.1554 46.0919 46.0919C51.1554 41.0284 54 34.1608 54 27C54 19.8392 51.1554 12.9716 46.0919 7.90812C41.0284 2.84464 34.1608 0 27 0C19.8392 0 12.9716 2.84464 7.90812 7.90812C2.84464 12.9716 0 19.8392 0 27C0 34.1608 2.84464 41.0284 7.90812 46.0919C12.9716 51.1554 19.8392 54 27 54ZM21.0305 32.9695C17.7398 29.6789 17.7398 24.3316 21.0305 21.041C24.3211 17.7504 29.6684 17.7504 32.959 21.041C33.9504 22.0324 35.5535 22.0324 36.5344 21.041C37.5152 20.0496 37.5258 18.4465 36.5344 17.4656C31.2609 12.1922 22.718 12.1922 17.4445 17.4656C12.1711 22.7391 12.1711 31.282 17.4445 36.5555C22.718 41.8289 31.2609 41.8289 36.5344 36.5555C37.5258 35.5641 37.5258 33.9609 36.5344 32.9801C35.543 31.9992 33.9398 31.9887 32.959 32.9801C29.6684 36.2707 24.3211 36.2707 21.0305 32.9801V32.9695Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-yellow-500 text-sm mt-[0.5vh] ml-[0vw] animate-pulse"><a href="https://www.findsmiley.dk/Sider/KontrolRapport.aspx?Virk5332231">Kontrolrapport</a></div>
          <p className="text-white text-sm mt-[0.5vh] ml-[0vw]">CVR: 34846162</p>
          <p className="text-white text-[10px] mt-[1vh] ml-[0vw] opacity-30">
            1.14.8
          </p>
        </div>
      </div>

      <div>
        <Nav />
      </div>
    </div>
  );
};

export default Load;
